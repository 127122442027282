(function ($, Ladda) {
    if (!Ladda) {
        throw new Error('loading-indicator requires Ladda to work');
    }

    var LoadingIndicator = function (element) {
        this.element = $(element);

        if (this.element.is(':input') || this.element.is('a')) {
            if (!this.element.attr(this.ATTR_DATA_INITIALIZED)) {
                this.element
                    .addClass('ladda-button')
                    .attr('data-style', this.element.attr('data-style') || 'zoom-in')
                    .wrapInner('<span class="ladda-label">')
                    .attr(this.ATTR_DATA_INITIALIZED, true);
            }

            this.handler = Ladda.create(this.element[0]);
        } else {
            this.handler = new OverlayLoadingIndicatorHandler(this.element);
        }
    };

    LoadingIndicator.prototype.ATTR_DATA_INITIALIZED = 'loading-indicator-initialized';

    LoadingIndicator.prototype.start = function () {
        this.handler.start();
        this.element.trigger('ffg.loading-indicator.start');
    };

    LoadingIndicator.prototype.stop = function () {
        this.handler.stop();
        this.element.trigger('ffg.loading-indicator.stop');
    };

    window.LoadingIndicator = LoadingIndicator;

    var OverlayLoadingIndicatorHandler = function (element) {
        this.element = element;
    };

    OverlayLoadingIndicatorHandler.prototype.start = function () {
        this.element.addClass('loader');
    };

    OverlayLoadingIndicatorHandler.prototype.stop = function () {
        this.element.removeClass('loader');
    };
})(jQuery, Ladda);
