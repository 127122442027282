(function ($) {
    var ErrorRenderer = function () {};

    ErrorRenderer.render = function (errors) {
        var ret = [];
        for (var err in errors) {
            if (errors.hasOwnProperty(err)) {
                var element = $(
                    'select[name="' + errors[err]['id'] + '"],' +
                    'textarea[name="' + errors[err]['id'] + '"],' +
                    'input[name="' + errors[err]['id'] + '"]'
                );

                if (element.length > 0) {
                    var errorMessage = $('<p />')
                        .addClass('help-block')
                        .text(errors[err]['message']);

                    element.parents('.form-group').addClass('has-error');
                    element.after(errorMessage);
                } else {
                    ret.push($('<div>')
                        .addClass('alert alert-danger')
                        .attr('role', 'alert')
                        .text(errors[err]['message']));
                }
            }
        }

        return ret;
    };

    window.ErrorRenderer = ErrorRenderer;
})($);
