(function ($, ErrorRenderer) {
    'use strict';

    var formDataRequestFileOpen = 'request_file_open',
        selectorModalForm = 'div.modal[role="dialog"]:not(.ignore-modal) form';

    /**
     * It's a kind of magic!
     *
     * @constructor
     */
    var ModalHandler = function () {};

    /**
     * OnSubmit gets called when modal form is submitted. It then decides what to do with the data.
     *
     * @param form
     * @param event
     */
    ModalHandler.prototype.onSubmit = function (form, event) {
        event.stopPropagation();
        event.preventDefault();

        var files = form.find(':file');
        if (!(form.attr('enctype') == 'multipart/form-data') || files.length == 0) {
            this.sendRequest(form);
            return;
        }

        form.data(formDataRequestFileOpen, files.length);

        var that = this;
        files.each(function () {
            var elem = $(this);

            if ('' == (elem.val().trim())) {
                that.decreaseRequestFileOpenCount(form);
                return;
            }

            elem.fileupload({
                dataType: 'json',
                replaceFileInput: false,
                done: function (e, data) {
                    that.appendFileIdToForm(form, data.jqXHR.responseJSON.data, elem.attr('name'));
                    that.decreaseRequestFileOpenCount(form);
                },
                error: function (jqXHR) {
                    var data = jqXHR.responseJSON;
                    that.appendErrorsToForm(form, data.errors);
                },
                always: function() {
                    elem.fileupload('destroy');
                },
            }).trigger('change');
        });
    };

    /**
     * Decreases the stored count how much file requests are still open and sends the form if it equals 0.
     *
     * @param form
     */
    ModalHandler.prototype.decreaseRequestFileOpenCount = function (form) {
        var open = form.data(formDataRequestFileOpen);
        if (!open || 0 >= open) {
            this.sendRequest(form);
            return;
        }

        form.data(formDataRequestFileOpen, --open);

        if (0 == open) {
            this.sendRequest(form);
        }
    };

    /**
     * Send the request to the url defined in form action attribute.
     *
     * @param form
     */
    ModalHandler.prototype.sendRequest = function (form) {
        var modal = this.getModalByForm(form);

        modal.trigger('ffg.modal.submit');

        var indicator = new LoadingIndicator(form.find(':submit'));
        indicator.start();

        var that = this;
        jQuery.post(form.attr('action'), form.serialize()).always(function (data, status, xhr) {
            indicator.stop();
            that.handleResponse(form, !data ? xhr : data, xhr);
        });
    };

    /**
     * Handle the response coming from api request executed above.
     *
     * @param form
     * @param data
     * @returns {boolean}
     * @param {XMLHttpRequest} xhr
     */
    ModalHandler.prototype.handleResponse = function (form, data, xhr) {
        var modal = this.getModalByForm(form);

        if (data.status >= 400) {
            if (typeof data == 'object') {
                if (data.responseJSON && typeof data.responseJSON == 'object') {
                    // If JSON use it as error object
                    data = data.responseJSON;
                    if (data.errors && typeof(data.errors) == 'object' && (Object.keys(data.errors).length > 0)) {
                        this.appendErrorsToForm(form, data.errors);
                    }
                } else if (data.responseText && typeof data.responseText == 'string') {
                    // if responseText is set use it as html and display it inside the modal

                    var parsedHtml = $.parseHTML($.trim(data.responseText));
                    // Check if id matches then the content can hopefully be replaced which removes the studder.
                    if ($(parsedHtml[0]).attr("id") == modal.attr("id")) {
                        modal.html($(parsedHtml[0]).html());
                    } else {
                        modal.parent().html(data.responseText);
                        var modal = this.getModalByForm(form);
                        modal.modal('show');
                    }
                }
            }

            modal.trigger('ffg.modal.error', [form, data, xhr]);
            return false;
        }

        form.trigger('ffg.modal.form-submitted', [form, data, xhr]);
        modal.trigger('ffg.modal.submitted', [form, data, xhr]);

        form[0].reset();
        form.find('div.alert.alert-danger').remove();
        modal.modal('hide');
    };

    /**
     * Returns the modal in parental order of the form.
     *
     * @param form
     * @returns {jQuery}
     */
    ModalHandler.prototype.getModalByForm = function (form) {
        return form.parents().filter('div.modal[role="dialog"]').first();
    };

    /**
     * Appends an Array of errors to the form, formatted by our friend, the {NotificationCenter}.
     *
     * @param form
     * @param errors
     */
    ModalHandler.prototype.appendErrorsToForm = function (form, errors) {
        form.find('div.alert.alert-danger').remove();
        form.find('.form-group').removeClass('has-error');
        form.find('.help-block').remove();

        form.find('div.modal-body, div.panel-body').prepend(ErrorRenderer.render(errors));
    };

    /**
     * Appends a hidden field with the file_id received from the file upload process.
     *
     * @param form
     * @param fileData
     * @param name
     */
    ModalHandler.prototype.appendFileIdToForm = function (form, fileData, name) {
        if (fileData && typeof fileData == 'object') {
            $('<input>').attr({
                type: 'hidden',
                name: name + '_id'
            }).val(fileData.id).appendTo(form);
        }
    };

    var body = $('body');
    if ($().tooltip) {
        // if a modal opens, hide the tooltips and disable the next toggling on mouseLeave
        body.on({
            'click': function () {
                $(this).tooltip('hide').one('show.bs.tooltip', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                });
            }
        }, '.tooltips[data-toggle="modal"]');
    }

    body.on({
        'submit': function (event) {
            var handler = new ModalHandler();
            handler.onSubmit($(this), event);
        }
    }, selectorModalForm);

    window.ModalHandler = ModalHandler;
}(jQuery, ErrorRenderer));
