(function ($, LoadingIndicator) {
    'use strict';

    var dataKeyModal = 'lazy-load-modal',
        reloadPageKey = 'reload-on-success',
        selectorLazyLoad = '[data-lazy-load-modal][data-toggle="modal"]',
        containerSelector = 'body div#modalsContainer';

    /**
     * It's a kind of magic!
     *
     * @constructor
     */
    var LazyModalLoader = function () {
        this.modalsContainer = $(containerSelector);
        if (this.modalsContainer.length === 0) {
            $('body').append($('<div id="modalsContainer"></div>'));
            this.modalsContainer = $(containerSelector);
        }
    };

    LazyModalLoader.prototype.ATTR_INDICATOR_ELEMENT = 'data-indicator-element';

    LazyModalLoader.prototype.load = function (element, event) {
        var target = this.getModal(element.attr('href')),
            that = this,
            indicatorElement = element.attr(this.ATTR_INDICATOR_ELEMENT) ? $(element.attr(this.ATTR_INDICATOR_ELEMENT)) : element,
            indicator = new LoadingIndicator(indicatorElement);

        if (!element.data(dataKeyModal) || target.length > 0) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        indicator.start();
        $.get(element.data(dataKeyModal))
          .done(function (data) {
            that.modalsContainer.append(data);
            target = that.getModal(element.attr('href'));
            if (target.length > 0) {
                target.trigger('ffg.lazy-modal.loaded', [element, target]);

                if (undefined !== element.data(reloadPageKey)) {
                    target.on('ffg.modal.submitted', function() {
                        location.reload();
                    });
                }
            }
        }).fail(function () {
          global.flashMessages.flashNotification('addError', 'Ein Fehler ist aufgetreten.');
        }).always(function () {
            indicator.stop();
        }).then(function () {
            element.trigger('click');
        });
    };

    LazyModalLoader.prototype.getModal = function (id) {
        return this.modalsContainer.filter(id).add(this.modalsContainer.find(id));
    };

    $('body').on({
        'click' : function (event) {
            var loader = new LazyModalLoader();
            loader.load($(this), event);
        }
    }, selectorLazyLoad);

    window.LazyModalLoader = LazyModalLoader;
})(jQuery, LoadingIndicator);
