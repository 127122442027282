import 'core-js/stable';

import * as Ladda from 'ladda';

global.Ladda = Ladda;

require('jquery');
require('jquery-ui');
require('bootstrap');

require('../../common/js/loading-indicator');
require('../../common/js/error-renderer');
require('../../common/js/modal-handler');
require('../../common/js/lazy-modal-loader');

require('autosize/dist/autosize');
require('../../../vendor/finanzcheck/flash-bundle/Resources/public/js/flash-message');
